import React, { useState, useEffect } from 'react';
export default function DropDownField({ data, changeValue, onBlur }) {

    const [field, setField] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);

    useEffect(() => {
        setField(data);
    }, [data])

    function getValue() {
        if (field.value === null) {
            return field.defaultText ? field.defaultText : field.options[0];
        } else {
            return field.value;
        }

    }

    function onFocus() {
        setCurrentValue(field.value);
    }

    function leaveEvent() {
        if (currentValue !== field.value) {
            onBlur();
        }
    }

    return (
        field ?
            <div className="mt-3">
                <label htmlFor={field.id} className="block text-sm font-medium leading-6 text-gray-900">
                    {field.label}
                </label>
                <select
                    id={field.id}
                    name={field.label}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={getValue()}
                    onChange={(x) => { changeValue(field.id, x.target.value); }}
                    onBlur={leaveEvent}
                    onFocus={onFocus}
                >
                    {field.options.map((x) => (
                        <option>{x}</option>
                    ))}
                </select>
            </div> : null
    );
}
