import { AccessDenied } from "./pages/AccessDenied";
import Home from "./pages/Home";
import { TemplateEditor } from "./pages/TemplateEditor";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/TemplateEditor/template/:id/',
        element: <TemplateEditor />
    },
    {
        path: '/TemplateEditor/:id/:draft/',
        element: <TemplateEditor />
    },
    {
        path: '/TemplateEditor/document/:documentId',
        element: <TemplateEditor />
    },
    {
        path: '/AccessDenied',
        element: <AccessDenied />
    }
];

export default AppRoutes;
