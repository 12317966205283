import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import TextField from './Inputs/TextField';
import DateField from './Inputs/DateField';
import DropDownField from './Inputs/DropDownField';
import CheckBox from './Inputs/CheckBox';
import moment from 'moment';
export default function Category({ title, data, doValidation }) {
    const [open, setOpen] = useState(false);
    const [fields, setFields] = useState(data);
    const [statusIcon, setStatusIcon] = useState(null);

    useEffect(() => {
        setFields(data);
    }, [data]);

    useEffect(() => {
        setStatusIcon(getStatusIcon());
    }, [fields]);

    const FieldTypes = {
        TextBox: "TextBox",
        RadioButtons: "RadioButtons",
        CheckField: "CheckField",
        Dropdown: "Dropdown",
        Datepicker: "Datepicker"
    };

    function toggle() {
        setOpen(!open);
    }

    function getStatusIcon() {
        let isValid = fields.every(obj => obj.valid === true);
        //fields.forEach((field) => {
        //    if (field.isMandatory && (field.value === null || field.value === "")) {
        //        isValid = false;
        //    }
        //});
        return isValid ? <CheckCircleIcon className="flex-0 w-5 text-green-400 mr-6" /> : <ExclamationCircleIcon className="flex-0 w-5 text-yellow-500 mr-6" />;
    }

    function changeFieldValue(id, value) {

        var fieldsCopy = [...fields];
        fieldsCopy.filter((field) => field.id === id)[0].value = value;
        setFields(fieldsCopy);
    }

    function getField(field) {

        var obj;

        switch (field.type) {
            case FieldTypes.TextBox:
                // Do something for TextBox fields
                obj = <TextField data={field} key={field.id} changeValue={changeFieldValue} onBlur={doValidation} />
                break;
            case FieldTypes.RadioButtons:
                // Do something for RadioButtons fields
                obj = <p key={field.id}>{field.label} - RadioButton</p>
                break;
            case FieldTypes.CheckField:
                // Do something for CheckField fields
                obj = <CheckBox data={field} key={field.id} changeValue={changeFieldValue} onBlur={doValidation} />
                break;
            case FieldTypes.Dropdown:
                // Do something for Dropdown fields
                obj = <DropDownField data={field} key={field.id} changeValue={changeFieldValue} onBlur={doValidation} />
                break;
            case FieldTypes.Datepicker:
                // Do something for Datepicker fields
                obj = <DateField data={field} key={field.id} changeValue={changeFieldValue} onBlur={doValidation} />
                break;
            default:
                // Handle unexpected field types
                obj = <p key={field.id}>Field type not found</p>
                break;
        }
        return obj;
    }

    function getLastPartOfString(string) {
        var parts = string.split('\\');
        return parts[parts.length - 1];
    }

    return (
        <div className="border-solid border-b border-gray-200 pb-6 mx-1">
            <div className="flex flex-col">
                <div className="mx-10 flex-1 flex cursor-pointer" onClick={toggle}>
                    <div className="flex-1 font-medium text-base">{getLastPartOfString(title)}</div>
                    {statusIcon}
                    {open ? <ChevronUpIcon className="flex-0 w-5 text-gray-400" /> : <ChevronDownIcon className="flex-0 w-5 text-gray-400" />}
                </div>
                <div className={(open ? 'mb-3 ' : 'hidden ') + "mx-10"} >
                    {fields.map((field) => (
                        getField(field)
                    ))}
                </div>
            </div>
        </div>
    );
}
