import axios from "axios";
import { GetAccessToken, Logout } from "../services/AuthenticationService";

export const GetClientInstance = async (useAuthentication = true) => {

   var headers = {      
   };

   if (useAuthentication) {
      let accessToken = await GetAccessToken();

      if (accessToken != null) {
         headers["Authorization"] = "Bearer " + accessToken;
      }
   }


   let client = axios.create({
      baseURL: window._env_.REACT_APP_API_BASE_URL,
      //timeout: 10000,
      headers: headers
   });

   client.interceptors.response.use((response) => {
      if (useAuthentication && response.status == 401) {
         console.log("401 received. Not logged in anymore.");
         Logout()
      }

      return response;

   }, (error) => {
      if (error.response && error.response.data) {
         return Promise.reject(error.response.data);
     }
     return Promise.reject(error.message);
 
   })

   return client;
}; 

