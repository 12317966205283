import {GetClientInstance} from "./ApiClientService";

export const GetFolders = async () => {
    console.log("GetFolders");

    let client = await GetClientInstance();

    return client.get('folders');
};

