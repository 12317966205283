import {GetClientInstance} from "./ApiClientService";

export const GetTemplates = async (templateType, virtualPath) => {
    let client = await GetClientInstance();
    
    if (virtualPath !== "") {
        return client.get(`templates?TemplateType=${templateType}&VirtualPath=${virtualPath}`);
    } else {
        return client.get(`templates?TemplateType=${templateType}`);
    }
};

export const GetTemplate = async (templateId) => {
    let client = await GetClientInstance();

    return client
        .get(`templates/${templateId}`);
};

export const GetTemplateFields = async (templateId) => {
    let client = await GetClientInstance();

    return client
        .get(`templates/${templateId}/requirements`);
};

export const ValidateTemplateFields = async(templateId, fields) => {
    let client = await GetClientInstance();

    return client
        .post(`templates/${templateId}/requirements`, fields);
};

export const GenerateTemplatePreview = async(templateId, fields, fileFormat = "PDF") => {
    let client = await GetClientInstance();

    return client
        .post(`templates/${templateId}`, {
           fileFormat: fileFormat,
           fieldGroups: fields 
        });
};