import React, { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
export default function CheckBox({ data, changeValue, onBlur }) {

    const [field, setField] = useState(null);

    useEffect(() => {
        setField(data);
    }, [data])

    function getValue() {
        if (field.value === null) {
            return field.defaultText.toLowerCase() === "true" ? true : false;
        } else {
            return field.value.toLowerCase() === "true" ? true : false;
        }
    }

    function change() {
        onBlur();
    }

    function getLastPartOfString(string) {
        var parts = string.split('\\');
        return parts[parts.length - 1];
    }

    return (
        field ?
            <div className="mt-3">
                <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                        <input
                            id={field.id}
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            checked={getValue()}
                            onChange={(e) => { changeValue(field.id, e.target.checked ? "true" : "false"); change(); }}
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                        <label htmlFor={field.id} className="font-normal text-gray-900">
                            {getLastPartOfString(field.label)}
                        </label>
                    </div>
                </div>
            </div> : null
    );
}
