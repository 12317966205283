import React, { useState, useEffect } from 'react'
import { FolderIcon, Squares2X2Icon, MagnifyingGlassIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import '../styles.css';
import WordLogo from '../Images/word.png'
import ExcelLogo from '../Images/excel.png'
import PowerPointLogo from '../Images/powerpoint.png'
import Spinner from "../components/Spinner";
import {GetFolders} from "../ApiClients/FolderApiClient";
import {GenerateTemplatePreview, GetTemplates} from "../ApiClients/TemplateApiClient";
import { EnsureLoggedIn, IsLoggedIn } from '../services/AuthenticationService';
import { useNavigate } from 'react-router-dom';

export default function Home() {

    

    // === STATE VARIABLES === //
    const [folders, setFolders] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [shownTemplates, setShownTemplates] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [currentPath, setCurrentPath] = useState("");
    const [numberOfResultsShown, setNumberOfResultsShown] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTotalTemplates, setCurrentTotalTemplates] = useState(templates.length);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(showPageNumber());
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();



    // === HOOKS === //
    //Initial hook. Gets all folders and all templates in root folder.
    useEffect( () => {

        if (!EnsureLoggedIn(navigate)) {
            return;
        }
        
        GetFolders()
            .then(function(response) {
                setFolders(response.data);
            })
            .catch(function(error) {
               alert(error.message); 
            });
        
        showAllTemplates();
    }, []);

    //Changes numbers of templates shown in folder based on what is selected in the dropdown.
    useEffect(() => {
        var cutTemplateList = templates.slice(0, numberOfResultsShown);
        setShownTemplates(cutTemplateList);
    }, [numberOfResultsShown]);

    //Updates 'currentPage'' const instantly. (There has to be a better way to do this?)
    useEffect(() => {
    }, [currentPage])

    //Hook triggered when setting 'startIndex'. Then sets 'endIndex'.
    useEffect(() => {
        setEndIndex(startIndex + numberOfResultsShown);
    }, [startIndex])

    //Hook triggered by 'endIndex' and determins start and end positions for data array (for templates).
    useEffect(() => {
        const pageData = templates.slice(startIndex, endIndex);
        setShownTemplates(pageData);
    }, [endIndex])


    // === FUNCTIONS === //
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function folderElement(folder, remInc, fullPath) {
        var remStyle = remInc + 'rem';

        return (
            <div key={fullPath}>
                <div
                    className={classNames(
                        isSelectedFolder(fullPath) ? 'bg-dania-100' : 'hover:bg-dania-100',
                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 cursor-pointer'
                    )}
                    onClick={() => onFolderClick(fullPath)}
                >
                    <FolderIcon
                        className={classNames(
                            isSelectedFolder(fullPath) ? 'text-dania-700' : 'text-gray-400 group-hover:text-dania-700',
                            'h-6 w-6 shrink-0'
                        )}
                        style={{ marginLeft: remStyle }}
                        aria-hidden="true"
                    />
                    {folder.path}
                </div>
                {(folder.subFolders !== null && folder.open) ? (
                    folder.subFolders.map((subfolder) => (
                        <>
                            {folderElement(subfolder, remInc + 1, fullPath + '/' + subfolder.path)}
                        </>
                    ))
                ) : null
                }
            </div>
        )
    }

    async function onFolderClick(fullPath) {
        setCurrentPath(fullPath);
        folderToggle(fullPath);
        setSelectedFolder(fullPath);
        setTemplates([])
        setShownTemplates([])
        setIsLoading(true);
        
        try {
            let response = await GetTemplates(null, fullPath)
            
            let responseTemplates = response.data;
            
            let shownTemplates = responseTemplates.slice(0, numberOfResultsShown);
            
            setTemplates(responseTemplates);
            setShownTemplates(shownTemplates);
            setCurrentTotalTemplates(responseTemplates.length);
            setIsLoading(false);
            
        } catch (e) {
            console.log(e.message);
        }
        
    }

    function folderToggle(fullPath) {
        var oldFolders = [...folders];
        var folderInPath = fullPath.split('/');
        var currentOldFolder = oldFolders.filter(x => x.path == folderInPath[0])[0];

        if (folderInPath.length > 1) {
            folderInPath.slice(1).forEach((subFolder) => {
                currentOldFolder = currentOldFolder.subFolders.filter(x => x.path == subFolder)[0];
            });
        }

        currentOldFolder.open = currentOldFolder.open != null ? !currentOldFolder.open : true;
        setFolders(oldFolders);
    }

    function isSelectedFolder(fullPath) {
        return fullPath === selectedFolder;
    }

    async function onTemplateClick(template) {

        if (template.templateType === "Word") {
            const currentURL = window.location.toString();
            const queryStringIndex = currentURL.indexOf("?");
            const queryString = queryStringIndex !== -1 ? currentURL.substring(queryStringIndex) : "";

            window.location.href = 'templateeditor/template/' + template.id + queryString;
        } else {
            await generateAndDownloadFile(template);
        }
    }

    async function generateAndDownloadFile(template) {
        
        try {
            let generateResponse = await GenerateTemplatePreview(template.id, [], "Default");
            let data = generateResponse.data;

            const documentBytesArray = base64ToBytes(data.bytes);
            const blob = new Blob([documentBytesArray], { type: 'application/pdf' });
            const anchor = document.createElement('a');
            anchor.download = `${template.name}.${data.fileName.split('.').pop()}`;

            anchor.style.display = "none";
            anchor.href = URL.createObjectURL(blob);

            document.body.appendChild(anchor);
            anchor.click();

            document.body.removeChild(anchor);
            URL.revokeObjectURL(anchor.href);
            
        } catch (e) {
            console.log(e.message);
        }
        
        
        
        // Generate(template.id, { fieldGroups: [] }, "file").then((x) => {
        //     const documentBytesArray = base64ToBytes(x.bytes);
        //     const blob = new Blob([documentBytesArray], { type: 'application/pdf' });
        //     const anchor = document.createElement('a');
        //     anchor.download = `${template.name}.${x.fileName.split('.').pop()}`;
        //
        //     anchor.style.display = "none";
        //     anchor.href = URL.createObjectURL(blob);
        //
        //     document.body.appendChild(anchor);
        //     anchor.click();
        //
        //     document.body.removeChild(anchor);
        //     URL.revokeObjectURL(anchor.href);
        // });
    }

    function base64ToBytes(base64) {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);

        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes;
    }

    async function showAllTemplates() {
        setCurrentPath("");
        setSelectedFolder("");
        setIsLoading(true);
        
        
        try {
            let response = await GetTemplates("", "");
            let templates = response.data;
            
            var y = templates.slice(0, numberOfResultsShown);
            setTemplates(templates);
            setShownTemplates(y);
            setCurrentTotalTemplates(templates.length);
            setIsLoading(false);

        } catch(e) {
            alert(e.message);
        }
        
    }

    async function getTemplatesBySearch(searchStr) {
        let currentTemplates = [...templates];

        if (searchStr !== "") {
            let filteredTemplates = currentTemplates.filter(template => template.name.toLowerCase().includes(searchStr.toLowerCase()));
            setShownTemplates(filteredTemplates);
            setCurrentTotalTemplates(filteredTemplates.length);
        } else {
            
            try {
                let response = await GetTemplates(null, currentPath);
                let data = response.data;
                
                let shownTemplates = data.slice(0, numberOfResultsShown);
                setTemplates(data);
                setShownTemplates(shownTemplates);
                setCurrentTotalTemplates(templates.length);
            } catch (e) {
                console.log(e.message);
            }
        }
    }

    async function search(event) {
        setSearchValue(event.target.value);
        await getTemplatesBySearch(event.target.value);
    }

    function changeNumberOfResults(event) {
        setNumberOfResultsShown(event.target.value);
    }

    function showPageNumber(endIndex) {
        if (endIndex > templates.length || endIndex < numberOfResultsShown) {
            return templates.length;
        } else {
            console.log(endIndex);
            console.log(currentTotalTemplates);
            if (endIndex > currentTotalTemplates || endIndex < numberOfResultsShown) {
                return currentTotalTemplates
            }
            return endIndex;
        }
    }

    function pageIndexing(page, incr) {
        let totalPages = Math.ceil(templates.length / numberOfResultsShown);

        if ((currentPage < totalPages && page > 0 && incr) || (currentPage <= totalPages && page > 0 && !incr)) {
            setStartIndex((page - 1) * numberOfResultsShown);
            setCurrentPage(page);
        }
    }

    function selectLogo(template) {
        if (template.templateType === "Excel") {
            return ExcelLogo;
        } else if (template.templateType === "Powerpoint") {
            return PowerPointLogo;
        } else if (template.templateType === "Word") {
            return WordLogo;
        }
    }

    function getStartIndex() {
        if (currentTotalTemplates === 0) {
            return 0;
        } else {
            return startIndex + 1;
        }
    }

    // === HTML TO RETURN === //
    return (
        <>
            <div className="flex h-screen">
                <div className="flex inset-y-0 z-50 w-2/12 min-w-px353 flex-col">
                    <div className="flex grow flex-col gap-y-6 overflow-y-hidden border-r border-gray-200 bg-gray-50 pb-4">
                        <div className="flex mt-14 pb-6 mx-4">
                            <div className="grow"></div>
                            <h1 className="grow-0 font-bold text-2xl font-normal px-auto"><b>Gennemse</b></h1>
                            <div className="grow"></div>
                        </div>
                        <div>
                            <ul role="list" className="space-y-1">

                                {/*Dette er et fast menupunkt der viser knappen "All templates"*/}
                                <div
                                    className={classNames(
                                        isSelectedFolder("") ? 'bg-dania-100' : 'hover:bg-dania-100',
                                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 mb-2 cursor-pointer'
                                    )}
                                    onClick={() => showAllTemplates()}
                                >
                                    <Squares2X2Icon
                                        className={classNames(
                                            isSelectedFolder("") ? 'text-dania-700' : 'text-gray-400 group-hover:text-dania-700',
                                            'h-6 w-6 shrink-0'
                                        )}
                                        style={{ marginLeft: '2rem' }}
                                        aria-hidden="true"
                                    />
                                    {/*{selectedFolder}*/}
                                    Alle skabeloner
                                </div>

                                {/*Her inds�ttes mapperne dynamisk*/}
                                {folders.map((folder) => (
                                    <div>
                                        {folderElement(folder, 2, folder.path)}
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <main className="py-14">
                        {/*"Templates" Headline*/}
                        <h1 className="font-bold text-3xl font-normal px-auto ml-16"><b>Skabeloner</b></h1>

                        {/*Divider*/}
                        <div className="text-center mt-4 ml-12">
                            <hr className="border-t border-gray-200 mb-4" style={{ maxWidth: '90%' }}></hr>
                        </div>

                        {/*"Choose" Headline*/}
                        <div className="mt-4 ml-16">
                            <b>V{'\u00E6'}lg en skabelon i listen nedenfor.</b>
                        </div>

                        <div className="flex w-10/12">
                            {/*Search bar*/}
                            <div className="flex">
                                <form onSubmit="" className="flex items-center mt-4 ml-16">
                                    <div className="relative">
                                        <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                        </span>
                                        <input
                                            type="text"
                                            value={searchValue}
                                            onChange={search}
                                            className="pl-10 pr-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-dania-300 border border-gray-300"
                                            placeholder={"S\u00F8g"}
                                        />
                                    </div>
                                </form>
                            </div>
                            {/*Dropdown*/}
                            <div className="flex-1 flex justify-end">
                                <select
                                    value={numberOfResultsShown}
                                    onChange={changeNumberOfResults}
                                    className="pl-10 pr-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-dania-300 border border-gray-300 mt-4"
                                >
                                    <option value="10">10 pr. side</option>
                                    <option value="20">20 pr. side</option>
                                    <option value="30">30 pr. side</option>
                                </select>
                            </div>
                        </div>
                        
                        {/*Table of templates*/}
                        <div className="w-10/12 mt-4 ml-16 table-outline" style={{position: "relative"}}>

                            {isLoading && (
                                <Spinner></Spinner>
                            )}
                            
                            <table className="divide-y divide-gray-200 w-full" style={isLoading ? {height: "500px"} : {}}>

                                <thead>
                                    <tr>
                                        <th className="px-6 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider rounded-md">
                                            Navn
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {shownTemplates.map(template => (
                                        <tr>
                                            <td className="px-6 py-4 whitespace-no-wrap hover:bg-dania-100">
                                                <div className="flex items-center cursor-pointer" onClick={() => onTemplateClick(template)} >
                                                    <div className="flex">
                                                        <img src={selectLogo(template)}></img>
                                                    </div>
                                                    <div className="flex-1">
                                                        <div className="ml-8">
                                                            <div className="text-sm leading-5 font-medium text-gray-900">
                                                                {template.name}
                                                            </div>
                                                            <div className="text-sm leading-5 font-small text-gray-700">
                                                                {template.description}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                        {/*Pagination*/}
                        <div className="w-10/12 mt-4 ml-16 text-center">
                            <div>Viser {getStartIndex()}-{showPageNumber(endIndex)} af {currentTotalTemplates}</div>
                            <div className="flex items-center justify-center mt-4">
                                <button
                                    className={`bg-white-500 outline-1 hover:bg-gray-100 text-black font-bold py-2 px-4 rounded-l border flex items-center justify-center`}
                                    style={{ width: '50px' }}
                                    onClick={() => pageIndexing(currentPage - 1, false)}
                                >
                                    <ChevronLeftIcon
                                        className="h-4 w-4 shrink-0"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button
                                    className={`bg-white-500 outline-1 hover:bg-gray-100 text-black font-bold py-2 px-4 rounded-r border flex items-center justify-center`}
                                    style={{ width: '50px' }}
                                    onClick={() => pageIndexing(currentPage + 1, true)}
                                >
                                    <ChevronRightIcon
                                        className="h-4 w-4 shrink-0"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
            </div >
        </>
    )
}
