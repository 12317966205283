import { GetClientInstance } from "../ApiClients/ApiClientService";
import { GetAccessTokenByTokenKey } from "../ApiClients/AuthenticationApiClient";

export const GetAccessToken = async () => {

    console.log("GetAccessToken called");
    let authData = GetAuthenticationData();

    if (!authData) {
        return null;
    }


    return authData.accessToken;
}

export const GetAuthenticationData = () => {

    var json = sessionStorage.getItem("auth");

    if (!json) {
        json = localStorage.getItem("auth");
    }

    if (!json) {
        return null;
    }

    return JSON.parse(json);
}

export async function ExchangeTokenKeyToAccessToken(tokenKey) {

    try {
        let response = await GetAccessTokenByTokenKey(tokenKey);

        let data = response.data;

        sessionStorage.setItem("auth", JSON.stringify(data));

    } catch(e) {
        console.log("ExchangeTokenKeyToAccessToken: " + e.message);

        if (e.response.status === 404) {
            return false;
        }
    }
    

    return true;

}

export function EnsureLoggedIn(navigate) {
    let isLoggedIn = IsLoggedIn();

    if (!isLoggedIn) {
        navigate("/AccessDenied");
        //For now we navigate to AccessDenied because we don't have any external login providers. Only the agreed authentication for the 3F case.
        //navigate("/login");
    }

    return isLoggedIn;
}


export const IsLoggedIn = () => {
    return GetAuthenticationData() != null;
}

export function Logout() {
    sessionStorage.removeItem("auth");
    localStorage.removeItem("auth");
}