import {GetClientInstance} from "./ApiClientService";


export const GetDocumentById = async (documentId) => {
    let client = await GetClientInstance();

    return client
        .get(`adapters/documents/${documentId}`)
}

export const SaveDocument = async (documentId, data, fileType, isDraft) => {
    let client = await GetClientInstance();

    return client
        .post(`adapters/documents/${documentId}`, {
           "data": data,
           "fileFormat": fileType,
           "isDraft": isDraft 
        });
}