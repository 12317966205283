import React, { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
export default function TextField({ data, changeValue, onBlur }) {

    const [field, setField] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);

    useEffect(() => {
        setField(data);
        setCurrentValue(data.value);
    }, [data])

    function getValue() {
        if (field.value === null) {
            return field.defaultText ? field.defaultText : "";
        } else {
            return field.value;
        }
    }

    function onFocus() {
        setCurrentValue(field.value);
    }

    function leaveEvent() {
        if (currentValue !== field.value) {
            onBlur();
        }
    }

    return (
        field ?
            <div className="mt-3">
                <label htmlFor={field.id} className="block text-sm font-medium leading-6 text-gray-900">
                    {field.label}
                </label>
                <div className="flex">
                    <div className="mt-2 rounded-md shadow-sm flex-1">
                        {(field.multiLine ?
                            <textarea rows="3" onChange={(x) => { changeValue(field.id, x.target.value); }} id={field.id} className={"resize-none block w-full rounded-md border-0 py-1.5 px-1 ring-1 ring-inset sm:text-sm sm:leading-6 focus:ring focus:outline-none " + (field.valid ? 'ring-gray-300' : 'ring-yellow-500')} onBlur={leaveEvent} onFocus={onFocus}>{getValue()}</textarea>
                            :
                            <input type="text" value={getValue()} onChange={(x) => { changeValue(field.id, x.target.value); }} id={field.id} className={"block w-full rounded-md border-0 py-1.5 px-1 ring-1 ring-inset sm:text-sm sm:leading-6 focus:ring focus:outline-none " + (field.valid ? 'ring-gray-300' : 'ring-yellow-500')} onBlur={leaveEvent} onFocus={onFocus} />
                        )}
                    </div>
                    <div className="pointer-events-none inset-y-0 right-0 flex-none items-center pl-3 flex mt-2">
                        <ExclamationCircleIcon className={"h-5 w-5 " + (field.valid ? 'text-gray-50' : 'text-yellow-500')} />
                    </div>
                </div>
            </div> : null
    );
}
