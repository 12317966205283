/* global Office */

export class WordHelper {
    InsertOoxml = (base64EncodeOoxml) => {
        var officeCmd = { "cmd": "insertStandardContentAsOoxml", "content": base64EncodeOoxml };

        try {
            Office.context.ui.messageParent(JSON.stringify(officeCmd));
        } catch (exe) {
            console.log("Unable to insert content", exe);
        }
    };

    InsertHtml = (base64EncodedHtml) => {
        var officeCmd = { "cmd": "insertStandardContentAsHtml", "content": base64EncodedHtml };

        try {
            Office.context.ui.messageParent(JSON.stringify(officeCmd));
        } catch (exe) {
            console.log("Unable to insert content", exe);
        }
    };

    CreateNewDocument = (fileContent) => {
        var createCmd = '{"cmd":"createNew","content":"' + fileContent + '"}';

        Office.context.ui.messageParent(createCmd);
    };
}

const wordHelper = new WordHelper();

export default wordHelper;